
jQuery(document).ready(function () {
  let $ = jQuery;
  $(function () {
      $(".set > button").on("click", function () {
          if ($(this).hasClass('active')) {
              $(this).attr('aria-expanded', false);
              $(this).removeClass("active");
              $(this).parent('.set').find('.content-accordion').attr('aria-expanded', false);
              $(this).siblings('.content-accordion').slideUp(200);
              $(".set > button span").removeClass("minus").addClass("plus");
          } else {
              $(".set > button span").removeClass("minus").addClass("plus");
              $(this).find("span").removeClass("plus").addClass("minus");
              $(this).attr('aria-expanded', true);
              $(this).parent('.set').find('.content-accordion').attr('aria-expanded', true);
              $(".set > button").removeClass("active");
              $(this).addClass("active");
              $('.content-accordion').slideUp(200);
              $(this).siblings('.content-accordion').slideDown(200);
          }
      });
  })
  $(function () {
      let checkWindow = $(window).width();
      if (checkWindow < 768) {
          let a = $('#menu-dropdown').appendTo('#box-mobile-menu');
          console.log(a);
      }
  })
  $(function () {
      $('.checkbox-checked').on('click', function () {
          if ($(this).is(':checked')) {
              $('.go-other-page').removeClass('disabled')
          }
          else {
              $('.go-other-page').addClass('disabled');
          }
      })
  })
});
